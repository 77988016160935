import { store } from '../store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ApiClient from '../util/apiClient'
import { FACILITY_AUDIT } from '../util/constants'
export const initialState = {
	facilityId: null,
	runner: {
		id: null,
		firstName: null,
		lastName: null,
		status: null
	},
	validRunner: false,
	postFacilityAuditLoginStatus: null,
	loginErrorStatusCode: null,
	activeFacility: {},
	postFacilityAuditStatus: null,
	validAudit: false,
	audit: {
		facility_id: null,
		runner_id: null,
		doors_clear: null,
		doors_clear_media: [],
		clear_exits: null,
		clear_exits_media: [],
		security_notes: '',
		total_locks: null,
		total_totes: null,
		total_carts: null,
		other_amenities: '',
		inventory_notes: '',
		free_of_trash: null,
		free_of_trash_media: [],
		pests: null,
		pests_media: [],
		water_intrusion: null,
		water_intrusion_media: [],
		visible_signage: null,
		visible_signage_media: [],
		lights_working: null,
		lights_working_media: [],
		additional_notes: '',
		units: []
	},
	uploadedImages: [],
	postFacilityAuditMediaStatus: null,
	deleteFacilityAuditMediaStatus: null
}

export const postFacilityAuditLogin = createAsyncThunk(
	'audit/postFacilityAuditLogin',
	async (data) => {
		const apiClient = new ApiClient()
		const { body, id } = data
		return await apiClient
			.post(`/facility/${id}/audit/login`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postFacilityAudit = createAsyncThunk(
	'audit/postFacilityAudit',
	async (data) => {
		const apiClient = new ApiClient()
		const { body, id } = data
		return await apiClient
			.post(`/facility/${id}/audit`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postFacilityAuditMedia = createAsyncThunk(
	'audit/postFacilityAuditMedia',
	async (data) => {
		const apiClient = new ApiClient()
		const { body, id } = data
		return await apiClient
			.post(`/facility/${id}/audit/media`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const deleteFacilityAuditMedia = createAsyncThunk(
	'audit/deleteFacilityAuditMedia',
	async (url) => {
		const apiClient = new ApiClient()
		return await apiClient
			.del(`/facility/audit/media?url=${url}`)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const auditSlice = createSlice({
	name: 'audit',
	initialState,
	reducers: {
		setValidRunner: (state, action) => {
			state.validRunner = action.payload
		},
		setActiveFacility: (state, action) => {
			state.activeFacility = action.payload
		},
		setValidAudit: (state, action) => {
			state.validAudit = action.payload
		},
		setAuditProperty: (state, action) => {
			state.audit[action.payload.key] = action.payload.value
		},
		setAuditUnitProperty: (state, action) => {
			const { index, key, value } = action.payload
			state.audit.units[index][key] = value
		},
		setAuditImage: (state, action) => {
			const { key, newImages } = action.payload
			const images = state.audit[key]
			state.audit[key] = [...(images || []), ...newImages]
		},
		setAuditUnitImage: (state, action) => {
			const { index, newImages } = action.payload
			const images = state.audit.units[index].unit_media
			state.audit.units[index].unit_media = [...(images || []), ...newImages]
		},
		resetAuditState: (state) => {
			state.facilityId = null
			state.runner.id = null
			state.runner.firstName = null
			state.runner.lastName = null
			state.runner.status = null
			state.validRunner = false
			state.activeFacility = {}
		},
		resetAudit: (state) => {
			state.audit = initialState.audit
		},
		resetPostFacilityAuditLoginStatus: (state) => {
			state.postFacilityAuditLoginStatus = null
		},
		resetLogInErrorStatusCode: (state) => {
			state.loginErrorStatusCode = null
		},
		resetPostFacilityAuditStatus: (state) => {
			state.postFacilityAuditStatus = null
		},
		resetPostFacilityAuditMediaStatus: (state) => {
			state.postFacilityAuditMediaStatus = null
		},
		resetUploadedImage: (state) => {
			state.uploadedImages = []
		},
		resetDeleteFacilityAuditMediaStatus: (state) => {
			state.deleteFacilityAuditMediaStatus = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(postFacilityAuditLogin.pending, (state) => {
				state.postFacilityAuditLoginStatus = 'PENDING'
			})
			.addCase(postFacilityAuditLogin.fulfilled, (state, { payload }) => {
				state.postFacilityAuditLoginStatus = 'FULFILLED'
				state.facilityId = payload.facility_id
				state.runner.id = payload.id
				state.runner.firstName = payload.first_name
				state.runner.lastName = payload.last_name
				state.runner.status = payload.status
				state.validRunner =
					payload.status === FACILITY_AUDIT.RUNNER_STATUS.ACTIVE && payload.id
						? true
						: false
			})
			.addCase(postFacilityAuditLogin.rejected, (state, action) => {
				state.postFacilityAuditLoginStatus = 'REJECTED'
				console.log(action.error.message)
				state.loginErrorStatusCode = action.error.message.includes('401')
					? 401
					: null
			})
			.addCase(postFacilityAudit.pending, (state) => {
				state.postFacilityAuditStatus = 'PENDING'
			})
			.addCase(postFacilityAudit.fulfilled, (state) => {
				state.postFacilityAuditStatus = 'FULFILLED'
			})
			.addCase(postFacilityAudit.rejected, (state) => {
				state.postFacilityAuditStatus = 'REJECTED'
			})
			.addCase(postFacilityAuditMedia.pending, (state) => {
				state.postFacilityAuditMediaStatus = 'PENDING'
			})
			.addCase(postFacilityAuditMedia.fulfilled, (state, { payload }) => {
				state.postFacilityAuditMediaStatus = 'FULFILLED'
				state.uploadedImages = payload
			})
			.addCase(postFacilityAuditMedia.rejected, (state) => {
				state.postFacilityAuditMediaStatus = 'REJECTED'
			})
			.addCase(deleteFacilityAuditMedia.pending, (state) => {
				state.deleteFacilityAuditMediaStatus = 'PENDING'
			})
			.addCase(deleteFacilityAuditMedia.fulfilled, (state) => {
				state.deleteFacilityAuditMediaStatus = 'FULFILLED'
			})
			.addCase(deleteFacilityAuditMedia.rejected, (state) => {
				state.deleteFacilityAuditMediaStatus = 'REJECTED'
			})
	}
})

const { actions, reducer } = auditSlice
export const {
	setValidRunner,
	setActiveFacility,
	setAuditProperty,
	setAuditUnitProperty,
	setAuditImage,
	setAuditUnitImage,
	resetAuditState,
	resetAudit,
	resetPostFacilityAuditLoginStatus,
	resetLogInErrorStatusCode,
	resetPostFacilityAuditStatus,
	setValidAudit,
	resetPostFacilityAuditMediaStatus,
	resetUploadedImage,
	resetDeleteFacilityAuditMediaStatus
} = actions
export default reducer
